import {
    HttpClient,
    HttpErrorResponse,
    HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
    delay,
    forkJoin,
    Observable,
    of,
    switchMap,
    throwError,
} from 'rxjs';

import { SimulationHistoricalRequest } from '~/app/shared/types/simulation-historical/simulation-historical-request.type';
import {
    SimulationHistoricalResponse,
} from '~/app/shared/types/simulation-historical/simulation-historical-response.type';

const MAX_RETRY = 80;

@Injectable({
    providedIn: 'root',
})
export class SimulationsService {
    constructor(
        private http: HttpClient,
        private translocoService: TranslocoService,
    ) {}

    getHistoricalSimulation(simulationParams: SimulationHistoricalRequest) {
        return this.http.post<Readonly<{
            requestId: string,
            expiresAt: string,
        }>>('/simulation', simulationParams)
            .pipe(
                switchMap((simulationRequest: {
                        requestId: string,
                        expiresAt: string,
                    }) => forkJoin([
                    this.processSimulation(simulationRequest.requestId),
                    of(simulationRequest.requestId),
                ])),
            );
    }

    private processSimulation(requestId: string, retryCount: number = 0): Observable<SimulationHistoricalResponse> {
        return this.http.get<SimulationHistoricalResponse>(`/simulation/${requestId}`, {
            observe: 'response',
        })
            .pipe(
                switchMap((response: HttpResponse<SimulationHistoricalResponse>) => {
                    if (response.status === 202 && retryCount < MAX_RETRY) {
                        return of(true).pipe(
                            delay(1000),
                            switchMap(() => this.processSimulation(requestId, retryCount + 1)),
                        );
                    }
                    if (response.status === 202 && retryCount >= MAX_RETRY) {
                        return throwError(() => new HttpErrorResponse({
                            error: { message: this.translocoService.translate('common.error_too_many_retry') },
                        }));
                    }
                    return of(response.body as SimulationHistoricalResponse);
                }),
            );
    }
}
