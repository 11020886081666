// eslint-disable-next-line max-classes-per-file
import { EntityType } from '~/app/shared/enums/entity-type.enum';
import { Period } from '~/app/shared/enums/period.enum';
import { SimulationHistoricalPeriodType } from '~/app/shared/enums/simulation-historical-period-type.enum';
import { SimulationHistoricalViewModeValue } from '~/app/shared/enums/simulation-historical-view-mode-value.enum';
import { ViewModeType } from '~/app/shared/enums/view-mode-type.enum';
import { EntityToLoad } from '~/app/shared/types/entity-to-load.type';
import { OperationSimulationUpdatingPortfolio } from '~/app/shared/types/operations/operation-simulation-updating-portfolio.type';
import { CreatePortfolio } from '~/app/shared/types/portfolio/create-portfolio.type';
import { ProjectionAllocation } from '~/app/shared/types/projection/projection-allocation.type';
import {
    SimulationCommonBenchmarkParam,
} from '~/app/shared/types/simulation-common/simulation-common-benchmark-param.type';
import { SimulationCommonReferenceRequest } from '~/app/shared/types/simulation-common/simulation-common-reference-request.type';

export class AddSharesToComparedEntitiesAction {
    static readonly type = '[SimulationHistorical] Add Shares To Compared Entities';

    constructor(
        public shares: EntityToLoad[],
    ) {
    }
}

export class AddCategoriesToComparedEntitiesAction {
    static readonly type = '[SimulationHistorical] Add Categories To Compared Entities';

    constructor(
        public categoryIds: number[],
    ) {
    }
}

export class AddComparisonListToComparedEntitiesAction {
    static readonly type = '[SimulationHistorical] Add comparison list to compared entities';

    constructor(
        public listId: number,
    ) {
    }
}

export class AddPortfoliosToComparedEntitiesAction {
    static readonly type = '[SimulationHistorical] Add Portfolios To Compared Entities';

    constructor(
        public portfolios: EntityToLoad[],
    ) {
    }
}

export class AddAllocationsToComparedEntitiesAction {
    static readonly type = '[SimulationHistorical] Add allocations To Compared Entities';

    constructor(
        public allocations: OperationSimulationUpdatingPortfolio[],
    ) {
    }
}

export class AddCreatingPortfolioToComparedEntitiesAction {
    static readonly type = '[SimulationHistorical] Add creating portfolio To Compared Entities';

    constructor(
        public portfolio: CreatePortfolio, public allocation: SimulationCommonReferenceRequest, public initialAllocation: ProjectionAllocation | undefined,
    ) {
    }
}

export class DeleteComparedEntityAction {
    static readonly type = '[SimulationHistorical] delete compared entity';

    constructor(
        public entityId: number,
        public entityType: EntityType,
    ) {
    }
}

export class DeleteAllComparedEntitiesAction {
    static readonly type = '[SimulationHistorical] delete all compared entities';
}

export class ResetAction {
    static readonly type = '[SimulationHistorical] Reset state';
}

export class UpdateDraftPeriodParamsAction {
    static readonly type = '[SimulationHistorical] Update draft period params';

    constructor(
        public type: SimulationHistoricalPeriodType | null,
        public range: {
            start: string,
            end: string,
        } | null,
        public value: Period | null,
    ) { }
}

export class ToggleBenchmarkAction {
    static readonly type = '[SimulationHistorical] toggle benchmark';

    constructor(
        public benchmark: SimulationCommonBenchmarkParam,
    ) {
    }
}

export class CancelLaunchSimulationAction {
    static readonly type = '[SimulationHistorical] Cancel launch simulation';
}

export class LaunchSimulationAction {
    static readonly type = '[SimulationHistorical] launch simulation';
}

export class CopyActiveInDraftAction {
    static readonly type = '[SimulationHistorical] copy active params in draft';
}


export class ChangeViewModeAction {
    static readonly type = '[SimulationHistorical] Change view mode';

    constructor(public viewMode: ViewModeType) {}
}

export class ResetViewModeDisplayAction {
    static readonly type = '[SimulationHistorical] Reset view mode display';
}

export class SetViewModeDisplayValuesAction {
    static readonly type = '[SimulationHistorical] Set viewMode display values';

    constructor(public display: SimulationHistoricalViewModeValue[]) {}
}
